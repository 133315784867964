/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
const gqlEndpoint =
  document.currentScript && document.currentScript.src.startsWith('https://staticdemo.ckbk.com/')
    ? 'https://apidemo.ckbk.com/graphql'
    : 'https://api.ckbk.com/graphql';
const containers = Array.from(document.querySelectorAll('.ckbk-price'));
containers.forEach(container => {
  const { productId, coupon, showOriginal } = container.dataset;
  if (productId) {
    fetch(gqlEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        operationName: 'GetPriceExternal',
        variables: {
          productId,
          coupon,
          provider: 'paddle',
        },
        query: `
            query GetPriceExternal($provider: String!, $productId: String!, $coupon: String) {
              Price(provider: $provider, productId: $productId, coupon: $coupon) {
                currency
                list_price
                offer_price
              }
            }
          `,
      }),
    })
      .then(r => r.json())
      .then(response => {
        const { currency, list_price, offer_price } = response.data.Price;
        const offerPrice = parseFloat(offer_price).toLocaleString(
          undefined,
          {
            style: 'currency',
            currency,
          },
        );
        if (showOriginal === 'true' && list_price !== offer_price) {
          const listPrice = parseFloat(list_price).toLocaleString(
            undefined,
            {
              style: 'currency',
              currency,
            },
          );

          container.innerHTML = `${offerPrice} <s>${listPrice}</s> `;
        } else {
          container.innerHTML = offerPrice;
        }
      });
  }
});
